import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/dashboard",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Dashboard/Dashboard")),
  },
  {
    exact: true,
    path: "/affiliate",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Affiliate")),
  },
  {
    exact: true,
    path: "/term",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/AboutUS/Terms")),
  },
  {
    exact: true,
    path: "/ViewFaqs",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Banner/Faqs")),
  },

  {
    exact: true,
    path: "/privacy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/AboutUS/Privacy")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
