// import React from 'react';
// import {Container,Grid,Box,makeStyles} from "@material-ui/core";

// import {Link} from "react-router-dom";
// import {FiFacebook} from "react-icons/fi";
// import {FaTelegramPlane} from "react-icons/fa";
// import {FaInstagram} from "react-icons/fa";
// import {FiTwitter} from "react-icons/fi";


// const useStyles = makeStyles((theme) => ({}));



// export default function footer() {
//   // const classes = useStyles();
//   const classes = useStyles();
  
//   return(
//     <Box>
//       <Container>
//         <Grid container spacing={2}>
//           <Grid item  lg={5} md={4} sm={8} xs={12}>
//             <Box style={{display:'flex',alignItems:"center",}}>
//               <Link to="/">Home</Link>
//               <Link to="/">Dashboard</Link>
//               <Link to="/">Privacy</Link>
//               <Link to="/">Terms and Conditions</Link>
//             </Box>
//           </Grid>
//           <Grid item lg={3} md={2} sm={2} xs={6} align="left">
//             <img src="images/footerlogo.png" alt="logo"/>
//           </Grid>
//           <Grid item lg={2}></Grid>
//           <Grid item lg={2} md={2} sm={2} xs={6}>
//             <Box style={{display:'flex',alignItems:'center',justifyContent:'space-between'}} 
//             // className={classes.socialicons}
//             >
//               <FiFacebook />
//               <FaTelegramPlane/>
//               <FaInstagram/>
//               <FiTwitter/>
//             </Box>
//           </Grid>

//         </Grid>
//       </Container>
//     </Box>

//   );
// }

// // export default footer;




import React from 'react'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    newsection:{padding:"10px"}
}));
const Footersection = () => {
    const classes=useStyles();
  return (
    <div className={classes.newsection}>Footersection</div>
  )
}

export default Footersection